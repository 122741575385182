import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React, { useEffect, useState, lazy, Suspense } from "react";

import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import { UserProvider } from "./UserContext";
import { WalletProvider } from "./WalletContext";
import EuCookieBanner from "./component/EuCookieBanner";
import "./i18n";

let Ban;
let TweetInspect;
if (process.env.REACT_APP_P) {
  Ban = lazy(() => import("./component/admin/Ban"));
  TweetInspect = lazy(() => import("./component/admin/TweetInspect"));
}

const Fairness = lazy(() => import("./component/Fairness"));
const Askaccess = lazy(() => import("./Askaccess"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const SignInSide = lazy(() => import("./SignIn"));
const PostLogin = lazy(() => import("./PostLogin"));
const Banned = lazy(() => import("./component/Banned"));
const Vpn = lazy(() => import("./component/Vpn"));
const Tos = lazy(() => import("./component/Tos"));
const renderLoader = () => <CircularProgress color="secondary" />;
export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/ban"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Ban />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/check/:screenname/:tweetid"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <TweetInspect />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/recap"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="recap" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/hos/:id"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="hos" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/access"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Askaccess />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/createcampaign"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="createcampaign" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/contest"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="contest" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/user"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="user" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/select"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="select" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        {process.env.REACT_APP_P ? (
          <WrapRoute
            needLogged
            adminroute
            path="/addproof"
            component={() => (
              <Suspense fallback={renderLoader()}>
                <Dashboard ext="addproof" />
              </Suspense>
            )}
          />
        ) : (
          ""
        )}
        <WrapRoute
          path="/score/:id"
          component={() => <Redirect to="/postlogin" />}
        />
        <WrapRoute
          path="/error/401"
          component={() => <Redirect to="/postlogin" />}
        />
        <WrapRoute
          path="/postlogin"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <PostLogin />
            </Suspense>
          )}
        />

        <WrapRoute
          needLogged
          path="/campaigns"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="campaignlist" />
            </Suspense>
          )}
        />
        <WrapRoute
          needLogged
          path="/createpool"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="createpool" />
            </Suspense>
          )}
        />
        <WrapRoute
          path="/fairness"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Fairness />
            </Suspense>
          )}
        />

        <WrapRoute
          needLogged
          path="/pool/:id"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="coinx" />
            </Suspense>
          )}
        />
        <WrapRoute
          path="/vpn"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Vpn />
            </Suspense>
          )}
        />
        <WrapRoute
          path="/tos"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Tos />
            </Suspense>
          )}
        />
        <WrapRoute
          needLogged
          path="/preido"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="preido" />
            </Suspense>
          )}
        />
        <WrapRoute
          needLogged
          path="/createbid"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="createbid" />
            </Suspense>
          )}
        />

        <WrapRoute
          needLogged
          path="/voting/:id"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="voting" />
            </Suspense>
          )}
        />
        <WrapRoute
          needLogged
          path="/votings"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <Dashboard ext="votings" />
            </Suspense>
          )}
        />
        <WrapRoute
          path="/error/:id"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <ErrorPage />
            </Suspense>
          )}
        />

        <WrapRoute
          // needLogged
          path="/banned"
          component={() => (
            <Suspense fallback={renderLoader()}>
              <EuCookieBanner />
              <Banned />
            </Suspense>
          )}
        />
        <Route
          path="/"
          exact
          component={() => (
            <Suspense fallback={renderLoader()}>
              <EuCookieBanner />
              <SignInSide />
            </Suspense>
          )}
        />
        <Route path="/">
          <Suspense fallback={renderLoader()}>
            <Redirect to="/error/404" />
          </Suspense>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function WrapRoute({ path, exact, component, needLogged, adminroute }) {
  const [wallet, setWallet] = useState({
    wallet: "",
    walletType: "",
    setWallet: (e, f) => {
      setWallet({ ...wallet, wallet: e, walletType: f });
    },
  });
  const [status, setStatus] = useState(200);
  const [data, setData] = useState(undefined);
  const fetchUser = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/score/me`,
        { withCredentials: true }
      );
      setStatus(res.status);
      setData(res.data);
    } catch (err) {
      // setStatus(401);
    }
    return true;
  };
  useEffect(() => {
    if (needLogged && data === undefined) {
      fetchUser();
    }
  }, [needLogged]);

  if (needLogged && data?.banned) {
    if (path !== "/banned") {
      return <Redirect to="/banned" />;
    }
    return <Route path={path} exact={exact} component={component} />;
  }
  if (
    !needLogged ||
    (status === 200 && (!data || !adminroute || data.role === "admin"))
  ) {
    return (
      <UserProvider value={data}>
        <WalletProvider value={wallet}>
          <Route path={path} exact={exact} component={component} />
        </WalletProvider>
      </UserProvider>
    );
  }
  if (path !== "/banned") {
    if (needLogged && !adminroute) {
      window.sessionStorage.setItem("last", window.location.href);
    }
    return <Redirect to="/" />;
  }
}
