import axios from "axios";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

export default function EuCookieBanner() {
  const [statistics, setStatistics] = React.useState(
    localStorage.getItem("statistics")
  );
  const [eu, setEu] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [addStat, setAddStat] = React.useState(true);
  const [close, setClose] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const x = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/iseu`
      );
      setEu(res?.data?.eu === true);
      if (res?.data?.eu === false) {
        setStatistics(true);
        localStorage.setItem("statistics", "true");
      }
    };
    if (statistics === null) {
      x();
    } else if (statistics === "true") {
      if (process.env.REACT_APP_GA_UA) {
        ReactGA.initialize(process.env.REACT_APP_GA_UA, {
          debug: process.env.NODE_ENV !== "production",
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }, [statistics]);

  const save = () => {
    localStorage.setItem("statistics", String(addStat));
    setClose(true);
  };
  if (statistics === null && eu === true) {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={!close}
          onClose={save}
        >
          <Alert onClose={save} severity="info" sx={{ width: "100%" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item align="center">
                <Typography>
                  {t("cookies.accordance")}
                  <a href="/tos">{t("cookies.tos")}</a>
                </Typography>
              </Grid>
              {open && (
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <Checkbox disabled align="center" checked />
                        </Grid>
                        <Grid item>
                          <Typography>{t("cookies.mandatory")}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <Checkbox
                            align="center"
                            checked={addStat}
                            onChange={() => setAddStat(!addStat)}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>{t("cookies.statistic")}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={2}>
                  {!open && (
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        {t("cookies.manage")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        save();
                        setClose(!close);
                      }}
                    >
                      {t("cookies.accept")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Alert>
        </Snackbar>
      </>
    );
  }
  return <></>;
}
